//Реакт
import React, { useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
//Библиотеки  и Api
import ApiAuth from "../../../../app/api/public/system/ApiAuth";
//Модули Layout
import LayoutAuth from "../../../modules/layouts/auth";
//Компоненты
import InputPassword from "../../../components/inputs/password/password";
import InputWrapper from "../../../components/input/__wrapper";
import Button from "../../../components/button";
//Стили
import './styles.css';
import '../../styles.css';

export default function ResetPasswordWithPage() {
    return (
        <LayoutAuth className="layout-auth">
            <ResetPasswordPage />
        </LayoutAuth>
    );
};
function ResetPasswordPage() {
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const navigate = useNavigate();

    const [routeSearchParams] = useSearchParams();
    const token = routeSearchParams.get('token');
    const email = routeSearchParams.get('email');

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await ApiAuth.ResetPassword({  email: email, token: token, password: password, password_confirmation: passwordConfirmation});
        if(data) {
            navigate('/')
        }
    };
    return (
        <div>
            <div>
                <h3>Сбросить пароль</h3>
            </div>
            <div className="auth__card">
                <div className="auth__card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="credentials">
                            <InputWrapper className="auth__input-grid auth__reset">
                                <InputPassword className="auth__reset_password" placeholder="Введите новый пароль" value={password} onChange={e => setPassword(e.target.value)}></InputPassword>
                            </InputWrapper>
                            <InputWrapper className="auth__input-grid auth__reset">
                                <InputPassword className="auth__reset_password" placeholder="Повторите пароль" value={passwordConfirmation} onChange={e => setPasswordConfirmation(e.target.value)}></InputPassword>
                            </InputWrapper>
                        </div>
                        <div className="auth__botom">
                            <div className="auth__botom_btn-wrap">
                                <Button type="submit" className="btn_rigth">Отправить</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};