import ReactQuill, { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

class CustomFormatAttributor extends Parchment.Attributor.Class {}

const CustomFormatClass = new CustomFormatAttributor('customFormat', 'ql', {
    scope: Parchment.Scope.INLINE,
    whitelist: [false, 'btn__color_blue']
});

Quill.register({
    'formats/customFormat': CustomFormatClass,
    'attributors/class/customFormat': CustomFormatClass
});
  

export default CustomFormatClass;