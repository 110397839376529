function resource($param){
    return {'index': '/', 'create': '/create', 'show': '/:id', ...$param};
}
const routes = {
    'authorized': {
        'admin': {
            'content': {
                'products': resource(),
                'categories': resource(),
                'promocodes': resource(),
                'promotions': resource(),
                'news': resource(),
            },
            'user': {
                'users': resource(),
                'roles': resource(),
            },
            'booking': {
                'balances': resource(),
                'balance-diff': resource(),
                'times': resource(),
                'bookings': resource(),
            },
            'order': {
                'certificates': resource(),
                'competitions': resource({'teamShow': '/:id'}),
                'bookings': resource(),
                'balances': resource(),
                'amo': resource(),
            },
            'event': {
                'platforms':  resource(),
                'banners':  resource(),
                'calendar':{
                    'calendars': resource(),
                    'categories': resource()
                }
            },
            'statistic': {
                'analytics': resource(),
                'parameters': resource()
            }
        },
        'account': {
            'home': resource(),
            'order': {
                'certificates': resource(),
            },
            'booking': {
                'bookings': resource(),
                'balances': resource(),
                'times': resource(),
            },
        },
        'common': {
            'profile': {'show': '/'},
        }
    },
};
export default routes;