import ReactQuill, { Quill } from 'react-quill';

const Inline = Quill.import('blots/inline');

class Link extends Inline {
    static create(value) {
      let node = super.create(value);
      value = this.sanitize(value);
      let prefix = this.prefix(value);
      node.setAttribute('href', prefix + value);
      node.setAttribute('target', '_blank');
      return node;
    }
  
    static formats(domNode) {
      return domNode.getAttribute('href');
    }
  
    static sanitize(url) {
      return sanitize(url, this.PROTOCOL_WHITELIST) ? url : this.SANITIZED_URL;
    }

    static prefix(value){
      const phoneRegex = /^\+?\d{11}$/;
      if (phoneRegex.test(value)) {
        return 'tel:';
      }

      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (emailRegex.test(value)) {
        return 'mailto:';
      }

      return '';
    }
  
    format(name, value) {
      if (name !== this.statics.blotName || !value) return super.format(name, value);
      value = this.constructor.sanitize(value);
      this.domNode.setAttribute('href', value);
    }
  }
  Link.blotName = 'link';
  Link.tagName = 'a';
  Link.SANITIZED_URL = 'about:blank';
  Link.PROTOCOL_WHITELIST = ['http', 'https', 'mailto', 'tel'];
  
  
  function sanitize(url, protocols) {
    let anchor = document.createElement('a');
    anchor.href = url;
    let protocol = anchor.href.slice(0, anchor.href.indexOf(':'));
    return protocols.indexOf(protocol) > -1;
  }
  
  Quill.register('formats/link', Link);
  
  export { Link as default };
  