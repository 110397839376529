//Реакт
import React, { useState, useContext, useLayoutEffect, useCallback} from "react";
import ReactDOM from 'react-dom/client';
//Библиотеки и Api
import ApiPromotion from "../../../../../app/api/authorized/admin/content/ApiPromotion";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import InputWrapper from "../../../../components/input/__wrapper";
import InputDatetime from "../../../../components/inputs/datetime/datetime";
import InputFile from "../../../../components/inputs/file/file";
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";
import ContentEditor from "../../../../components/quill";
//Стили
import './styles.css';
//MUI
import DeleteIcon from '@mui/icons-material/Delete';

export default function PromotionShow({ ...other}){
  return <Page ApiModel={ApiPromotion} { ...other}>
    <PromotionShowContent { ...other}/>
  </Page>
}

function PromotionShowContent({ ...other}){
  const contextPage = useContext(ContextPage);
  const contextContent = useContext(ContextContent);
  const [_id, set_id] = useState(null);
  const [_name, set_name] = useState('');
  const [_file_client, set_file_client] = useState(undefined);
  const [_file_server, set_file_server] = useState(null);
  const [_annotation, set_annotation] = useState(undefined);
  const [_priority, set_priority] = useState(0);
  const [_url, set_url] = useState('');
  const [_slug, set_slug] = useState('');
  const [_date_start, set_date_start] = useState('');
  const [_date_end, set_date_end] = useState('');
  const [_created, set_created] = useState(null);
  const [_updated, set_updated] = useState(null);
  const [_status, set_status] = useState(1);
  const [_content, set_content] = useState(undefined);
  const [_defaultContent, set_default_content] = useState(null);
  const [_defaultAnnotation, set_default_annotation] = useState(null);
  const [_timestamp, set_timestamp] = useState(new Date().getTime());

  let customDataToServer = {};
  if(_file_client !== undefined) customDataToServer.file = _file_client;
  if(_annotation !== undefined) customDataToServer.annotation = _annotation;
  if(_content !== undefined) customDataToServer.content = _content;

  contextContent._dataToServer.current = {
    name: _name,
    priority: _priority,
    url_page: _url,
    slug: _slug,
    date_start: _date_start,
    date_end: _date_end,
    is_active: _status,
    ...customDataToServer
  };

  useLayoutEffect(()=>{
    contextContent._handleSetData.current = (data) =>{
      set_id(data.id);
      set_name(data.name);
      set_file_server(data.file);
      set_url(data.url_page);
      set_slug(data.slug);
      set_priority(data.priority);
      set_date_start(data.date_start);
      set_date_end(data.date_end);
      set_created(data.created_at);
      set_updated(data.updated_at);
      set_status(data.is_active);
      set_default_content(data.content);
      set_default_annotation(data.annotation)
      set_timestamp(new Date().getTime());
      contextPage.setTitle(`Акция:  ${data.name}`);
      contextContent.setTitle(`Акция:  ${data.name}`);
      contextPage.setName(data.name);
    };
    contextContent.doLoad();
  },[]);

  const handleSetFile = (e)=>{
    let data = e.target.files[0];
    if(data) {
      const alowedExtensions = ['jpg', 'jpeg', 'png'];
      const fileExtension = data.name.split('.').pop().toLowerCase();
      if(alowedExtensions.includes(fileExtension)){
        set_file_client(data);
        const reader = new FileReader();
        reader.onload = (e) => {
          set_file_server({
            url: e.target.result
          })
        };
        reader.readAsDataURL(data);
      }
    }
  }

  const handleIsStatusChange = () => {
    set_status(Number(!_status));
  }
  const handleNameChange = (event) => {
    const slug = transliterate(event.target.value)
    .toLowerCase()
    .replace(/ /g, '_')
    .replace(/[^a-z0-9_]/g, '');
    set_slug(slug);
    set_name(event.target.value);
    };
    
  function transliterate(value){
    const converter = {
    'а': 'a', 'б': 'b', 'в': 'v', 'г': 'g', 'д': 'd',
    'е': 'e', 'ё': 'e', 'ж': 'zh', 'з': 'z', 'и': 'i',
    'й': 'y', 'к': 'k', 'л': 'l', 'м': 'm', 'н': 'n',
    'о': 'o', 'п': 'p', 'р': 'r', 'с': 's', 'т': 't',
    'у': 'u', 'ф': 'f', 'х': 'h', 'ц': 'c', 'ч': 'ch',
    'ш': 'sh', 'щ': 'sch', 'ь': '', 'ы': 'y', 'ъ': '',
    'э': 'e', 'ю': 'yu', 'я': 'ya',
    'А': 'A', 'Б': 'B', 'В': 'V', 'Г': 'G', 'Д': 'D',
    'Е': 'E', 'Ё': 'E', 'Ж': 'Zh', 'З': 'Z', 'И': 'I',
    'Й': 'Y', 'К': 'K', 'Л': 'L', 'М': 'M', 'Н': 'N',
    'О': 'O', 'П': 'P', 'Р': 'R', 'С': 'S', 'Т': 'T',
    'У': 'U', 'Ф': 'F', 'Х': 'H', 'Ц': 'C', 'Ч': 'Ch',
    'Ш': 'Sh', 'Щ': 'Sch', 'Ь': '', 'Ы': 'Y', 'Ъ': '',
    'Э': 'E', 'Ю': 'Yu', 'Я': 'Ya',
  };
  
  return value.trim()
    .replace(/ +/g, ' ')
    .replace(/[а-яё]/gi, (char) => {
      return converter[char.toLowerCase()] || char;
      })
    .replace(/ +/g, '_');
  }

  return <>
    {!contextContent.isCreate ?
    <InputWrapper>
      <InputText className="pages__show-input" value={_id} readOnly={true}>id</InputText>
    </InputWrapper> 
    : null }
    <InputWrapper>
      <InputText className="pages__show-input" value={_name} readOnly={!contextContent.isCreateOrEdit} onChange={handleNameChange}>Название акции</InputText>
    </InputWrapper>
    <InputWrapper>
        <InputFile className="pages__show-input"
            timestamp={_timestamp}
            file={_file_client} value={_file_server ? _file_server.name : null}  
            url = {_file_server ? _file_server.url : null}
            onChange={handleSetFile}
            placeholder={_file_server ? _file_server.name : null}
            readOnly={!contextContent.isCreateOrEdit} 
        >Файл</InputFile>
    </InputWrapper>
    <InputWrapper>
      <ContentEditor className="pages__show-input" value={_annotation} maxLength={500} config="minimal" defaultContent={_defaultAnnotation} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_annotation(e.target.value)}>Краткое описание</ContentEditor>
    </InputWrapper>
    <InputWrapper>
      <InputNumber className="pages__show-input" value={_priority} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_priority(e.target.value)}>Приоритет</InputNumber>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_url} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_url(e.target.value)}>Страница акции</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputText className="pages__show-input" value={_slug} readOnly={true}>Слаг</InputText>
    </InputWrapper>
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_date_start} readOnly={!contextContent.isCreateOrEdit}  onChange={e => set_date_start(e.target.value)}>Дата активации</InputDatetime>
    </InputWrapper> 
    <InputWrapper>
      <InputDatetime className="pages__show-input" value={_date_end} readOnly={!contextContent.isCreateOrEdit}  onChange={e => set_date_end(e.target.value)}>Дата окончания</InputDatetime>
    </InputWrapper>
    <InputWrapper>
      <InputCheckbox className="pages__show-input" value={_status} readOnly={!contextContent.isCreateOrEdit}  onClick={handleIsStatusChange}>Актив.</InputCheckbox>
    </InputWrapper>
    <InputWrapper>
      <ContentEditor className="pages__show-input" value={_content} maxLength={5000} defaultContent={_defaultContent} readOnly={!contextContent.isCreateOrEdit} onChange={e => set_content(e.target.value)}>Контент</ContentEditor>
    </InputWrapper>
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_created} readOnly={true}>Создан</InputDatetime>
      </InputWrapper> 
    : null }
    {!contextContent.isCreate ? 
      <InputWrapper>
        <InputDatetime className="pages__show-input" value={_updated} readOnly={true}>Изменен</InputDatetime>
      </InputWrapper> 
    : null }
  </>
}
