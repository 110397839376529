//Реакт
import React, { useLayoutEffect, useEffect, useContext, useState } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
//Для авторизованного пользователя
import ApiBookingByCardAuth from "../../../../app/api/authorized/account/booking/ApiBookingByCard";
import ApiBookingByBalanceAuth from "../../../../app/api/authorized/account/booking/ApiBookingByBalance";
//Для неавторизованного пользователя
import ApiBookingByCertificate from "../../../../app/api/authorized/account/booking/ApiBookingByCertificate";
import ApiBookingByCardPublic from "../../../../app/api/public/booking/booking/ApiBookingByCard";
import ApiBookingByBalancePublic from "../../../../app/api/public/booking/booking/ApiBookingByBalance";
//Для любого пользователя
import ApiCertificateProducts from "../../../../app/api/public/products/ApiCertificateProducts";
//Модули Content
import Page from "../../../modules/page";
import { ContextPage } from "../../../modules/page/pageContext";
import { ContextContent } from "../../../modules/content/contentContext";
import BookingComponent from "../../../modules/booking";
//Компоненты
import Carousel from "../../../components/carousel";
import TilePanel from "../../../components/tile-panel/tilePanel";
import CertificateTile from "../../../components/product-tiles/certificates/certificateTile";
import InputPhone from "../../../components/inputs/phone/phone";
import InputText from "../../../components/inputs/text/text";
import InputEmail from "../../../components/inputs/email/email";
import Modal from "../../../components/modals/modal";
import Button from "../../../components/button/button";
//Стили
import './styles.css';

export default function AccountHomeIndex({ ...other }) {
    return (
        <Page {...other}>
            <AccountHomeIndexContent {...other} />
        </Page>
    );
}

function AccountHomeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);
    const [isModalBuy, setIsModalBuy] = useState(false);
    const [_certificateData, set_certificateData] = useState(null);
    const [_name, set_name] = useState(null);
    const [_email, set_email] = useState(null);
    const [_phone, set_phone] = useState(null);
    const [_promocode, set_promocode] = useState(null);
    const [_chosenProductId, set_chosenProductId] = useState(null)
    const images = [
        'https://www.resizepixel.com/Image/hvgfnfhujljm/Preview/promotion_1.jpg?v=92f3791a-aeda-4073-b525-e3a9649a0802',
        'https://www.resizepixel.com/Image/kqaebjezkfvq/Preview/promotion_13.jpg?v=3b2fbb9e-709a-4cf9-9570-9cbd8753330d',
        'http://localhost:3000/promotion_1.jpg',
    ];

    const isAuth = useSelector(state => state.app.isAuth);

    const handleSelectCertificate = (id) => {
        set_chosenProductId(id);
        setIsModalBuy(true);
    }

    const handleBuyCertificate = () => {
        const fetchData = async () => {
            try {
                const apiBuyCertificate = ApiCertificateProducts.Update(_chosenProductId, {name: _name, email: _email, phone: _phone, promocode: _promocode});
                const responseData = await apiBuyCertificate.getResponse();
                if(responseData.payUrl) {
                    window.open(responseData.payUrl);
                }
            } catch (error) {}
        };

        fetchData();
        set_chosenProductId(null);
        setIsModalBuy(false);
    }

    //преобразование данных о продуктах
    const getTileData = (data) => {
        if (!data) return [];
      
        return data.map((tile) =>
                <CertificateTile
                    onClick={handleSelectCertificate}
                    key={tile.id} 
                    id={tile.id} 
                    title={tile.title ?? null} 
                    body={tile.terms ?? null} 
                    price={tile.price ?? null} 
                    oldPrice={tile.old_price ?? null} 
                    minutes={tile.minutes ?? null} 
                    min={tile.min_people ?? null}
                    max={tile.max_people ?? null}
                    gift={true}
                />
            );
      };

    useLayoutEffect(() => {
        contextPage.setTitle(`Главная`);
        contextContent.setTitle(`Главная`);
    }, [contextContent, contextPage]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const apiCertificateProducts = ApiCertificateProducts.Index();
                const responseData = await apiCertificateProducts.getResponse();
                set_certificateData(responseData)
                
            } catch (error) {}
        };

        fetchData();
    }, []);

    return (
        <>
            <div className="home--top-container">
                <BookingComponent
                    apiBookingByCard={isAuth ? ApiBookingByCardAuth : ApiBookingByCardPublic}
                    apiBookingByCertificate={ApiBookingByCertificate}
                    apiBookingByBalance={isAuth ? ApiBookingByBalanceAuth : ApiBookingByBalancePublic}
                    className="booking__card-modal"
                />
                {/* <div className="home--carousel-container">
                    <Carousel images={images}/>
                </div> */}
            </div>
            <TilePanel tiles={getTileData(_certificateData)} />
            <Modal
                title={'Покупка сертификата'}
                isOpenModal={isModalBuy}
                closeModal={()=>setIsModalBuy(false)}
                className="home_certificate-buy-modal"
            >
            <div className="home_certificate-buy-modal-content">
                <InputText className="home_certificate-buy-modal-content_row"  value={_name} onChange={(e) => { set_name(e.target.value) }}>Имя</InputText>
                <InputPhone className="home_certificate-buy-modal-content_row" value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>
                <InputEmail className="home_certificate-buy-modal-content_row" value={_email} onChange={(e) => { set_email(e.target.value) }}>Почта</InputEmail>
                <InputText className="home_certificate-buy-modal-content_row"  value={_promocode} onChange={(e) => { set_promocode(e.target.value) }}>Промокод</InputText>
                <Button onClick={handleBuyCertificate} className="home_certificate-buy-modal-content_row--button">Купить</Button>
            </div>
        </Modal>
        </>
    );
}