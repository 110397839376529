import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";
import Formatter from "../../../../models/Formatter";

export class NewsFormatter extends Formatter{}
export default class ApiNews extends ApiModelWithAuth{
    pathDef = 'authorized/admin/content/news';

    store(params){
        this.__type = "POST";
        this.__subtype = "formData";
        this.isSendFile = true;
        this.__path = ('store' in this.paths) ? this.paths.store : this.pathDef;
        this.__params = this.getParamsForStore(params);
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
        }
    }

    update(id, params){
        this.__type = "POST";
        this.__subtype = "formData";
        this.isSendFile = true;
        this.__path = ('update' in this.paths) ? this.paths.update+`/${id}` : (this.pathDef) ? this.pathDef+`/${id}` : null;
        this.__params = this.getParamsForUpdate({...params, _method: 'PATCH'});
        this.formatterResponseData = (rdata) =>{
            return rdata.response.data;
         }
    }

    formatterItem = NewsFormatter.Item;
}

