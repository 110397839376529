//Реакт
import React from "react";
//Компоненты
import ButtonLink from '../button-link';
//Material UI
import VisibilityIcon from '@mui/icons-material/Visibility';
//Стили
import "./styles.css";

export default function ButtonLinkShow({...other}){

    return <ButtonLink
        {...other}
    >
        <VisibilityIcon style={{fill: 'rgb(80 157 207)' }} />
        {/* <VisibilityIcon style={{fill: "rgb(2 113 185)"}} /> */}
    </ButtonLink>
}