//Реакт
import React, { useLayoutEffect, useContext, useRef, forwardRef, useState, useEffect } from "react";
//Контент
import { ContextList } from "./listContext";
import { ProviderList } from "./listProvider";
//Компоненты
import ListBodyItem from "./__body-item";
import ListHeaderItem from "./__header-item";
import Table from "../../modules/table";
import TableHeader from "../../modules/table/__header";
import TableFilters from "../../modules/table/__filters";
import TableHeaderRow from "../../modules/table/__header-row";
import TableBody from "../../modules/table/__body";
import TableRow from "../../modules/table/__row";
import Pagination from "./__pagination";
import Zoom from "./__zoom";
import Filters from "../filters";
import { SystemAbort404 } from "../../pages/public/system/Abort404";
import { SystemAbort403 } from "../../pages/public/system/Abort403";
//Material UI
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import { IconButton } from '@mui/material';
import { LinearProgress  } from '@mui/material';
import AddTaskOutlinedIcon from '@mui/icons-material/AddTaskOutlined';
import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import SyncIcon from '@mui/icons-material/Sync';
//Стили
import "./styles.css";
import Select from "../../components/inputs/select";
import Button from "../../components/button/button";

export default function ListWithProvider({ ...other }) {
    const listRef = useRef(null);
    useLayoutEffect(() => {
        if (listRef.current) {
            listRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [other]);
    return (
        <ProviderList {...other}>
            <ListRef {...other} ref={listRef} />
        </ProviderList>
    );
}
function List({ ApiModel, header, filters, renderRow, searchParams, tableClass, useRowSelect=false, functions = {}, isActiomLoading=false }, ref) {
    const {zoomLevel, currentPage, setCurrentPage, list, lastPage, isLoad, isShowFilters, setIsShowFilters, codeApi } = useContext(ContextList);
    const [_selectedFunctionKey, set_selectedFunctionKey] = useState('')
    const [_selectedRows, set_selectedRows] = useState([]);
    const [_selectedFunction, set_selectedFunction] = useState(null);

    useEffect(() => {
        set_selectedRows([]);
    }, [list]);

    if(codeApi === 404) return <SystemAbort404/>
    else if(codeApi === 403) return <SystemAbort403/>

    const actionPagination = (i) => {
        if(currentPage === i) return;
        setCurrentPage(i);
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: "smooth" });
        }
    }

    const handleRowClick = (identifier) => {
        if(useRowSelect){
            set_selectedRows(prevSelected => {
                if (prevSelected.includes(identifier)) {
                    return prevSelected.filter(i => i !== identifier);
                } else {
                    return [...prevSelected, identifier];
                }
            });
        }
    };

    const functionsMap = Object.keys(functions).reduce((acc, key) => {
        acc[key] = key
        return acc;
    }, {});

    const handleFunctionChange = (event) => {
        const key = event.target.value;
        set_selectedFunctionKey(key);
        const func = functions[key];
        set_selectedFunction(() => func);
    };

    const handleFunctionUse = async () => {
        if (_selectedFunction && !isActiomLoading) {
            let dataArr = [];
            _selectedRows.forEach((value) => {
                dataArr.push(list[value]);
            });

            _selectedFunction(dataArr);
        }
    };

    return <div ref={ref} className="w-100">
        <div className="list-setting">
            <div className="list-settings__row">
                <Zoom/>
                <div className={`${Object.keys(functions).length > 0 && useRowSelect ? 'list-settings__row_action--w-100' : 'list-settings__row_action'}`}>
                    {Object.keys(functions).length > 0 && useRowSelect &&
                        <Select
                            className="list-settings__row_action-select"
                            options={functionsMap}
                            value={_selectedFunctionKey}
                            onChange={handleFunctionChange}
                            placeholder="Выберите операцию ..."
                        />}
                    { Object.keys(functions).length > 0 && useRowSelect && <>{!isActiomLoading ? <Button onClick={handleFunctionUse}>Применить</Button> : <SyncIcon className="spin"/>}</>}
                    { filters && filters.length > 0 &&
                        <div>
                            {!isShowFilters ? 
                            <IconButton size="large" title="Показать фильтры" onClick={()=>setIsShowFilters(true)}><FilterAltIcon fontSize="inherit"/></IconButton>
                            :
                            <IconButton size="large" title="Скрыть фильтры" onClick={()=>setIsShowFilters(false)}><FilterAltOffIcon fontSize="inherit"/></IconButton>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
        <div className="overflow"  style={{ zoom: `${zoomLevel}%` }}>
            {isLoad ? 
                <LinearProgress sx={{  width: '100%', height: '7px', color: '#0d6efd'}} /> 
            : <div style={{  width: '100%', height: '7px'}}></div>}
            <Table className={`table ${tableClass ?? ''}`}>
                <TableHeader>
                    <TableHeaderRow>
                        {useRowSelect ? 
                            <ListHeaderItem key='use'  isSort={false} name='use'>
                                <AddTaskOutlinedIcon></AddTaskOutlinedIcon>
                            </ListHeaderItem> 
                        : null}
                        {header}
                    </TableHeaderRow>
                </TableHeader>
                {isShowFilters && filters ?
                <TableFilters col={header.length}>
                    <Filters filters={filters}/>
                </TableFilters>
                : null}
               <TableBody>
                        {list.map((row, i) => {
                            const isSelected = _selectedRows.includes(i);
                            return (
                                <TableRow
                                    key={i}
                                    onClick={() => handleRowClick(i)}
                                >
                                    {useRowSelect ? <ListBodyItem key="use">{!isSelected ? <CheckBoxOutlineBlankOutlinedIcon/> : <CheckBoxOutlinedIcon/>}</ListBodyItem> : null}
                                    {(renderRow) ? renderRow(row, i) : ''}
                                </TableRow>
                            );
                        })}
                    </TableBody>
            </Table>
        </div>
        {currentPage ? <Pagination
            current={currentPage}
            last={lastPage}
            action={actionPagination}
        /> : null}
    </div>
}
let ListRef = forwardRef(List);