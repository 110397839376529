//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiOrderCertificates from "../../../../../app/api/authorized/admin/order/ApiOrderCerificates";
import ApiOrderToEmail from "../../../../../app/api/authorized/admin/order/ApiOrderToEmail";
import ApiActivCertificates from "../../../../../app/api/authorized/admin/order/ApiActiveCertificates";
import ApiGenerateCertificate from "../../../../../app/api/authorized/admin/order/ApiGenerateCertificate";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
import MyModal from "../../../../components/modals/modal/index"
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import MultiSelect from "../../../../components/inputs/milti-select";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
import InputPhone from "../../../../components/inputs/phone/phone";
import Button from "../../../../components/button/button";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css';

export default function OrderCertificateIndex({ ...other }) {
    return <Page {...other}>
        <OrderCertificateIndexContent {...other} />
    </Page>
}

function OrderCertificateIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_phone, set_phone] = useState('');
    const [_email, set_email] = useState('');
    const [_code, set_code] = useState('');
    const [_price, set_price] = useState('');
    const [_productIds, set_productIds] = useState([]);
    const [_promocodeName, set_promocodeName] = useState('');
    const [_isActionLoading, set_isActionLoading] = useState(false);
    const [_sendTo, set_sendTo] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [_sendEmailData, set_sendEmailData] = useState([]);

    const products = useSelector(state => state.adminOption.products);

    const setMultiEmailData = (data) => {
        openModal();
        set_sendEmailData(data);
    };

    const sendMultiEmail = () => {
        const fetchEmailSend = async () => {
            // Извлекаем идентификаторы заказов из массива data
            const orders = _sendEmailData.map(item => item.id);
            set_isActionLoading(true);
            // Создаем API вызов с необходимыми параметрами
            let apiEmailSend = ApiOrderToEmail.Store({
                email: _sendTo, // Замените на нужный email
                orders: orders // Передаем массив идентификаторов заказов
            });

            await apiEmailSend.getResponse({});
            set_isActionLoading(false);
            set_sendEmailData([])
            closeModal()
        };

        fetchEmailSend();
    };

    const deactivateMultiCertificate = (data) => {

        const fetchDeactivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 0,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchDeactivate();
    };

    const activateMultiCertificate = (data) => {

        const fetchActivate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiActiveSet = ApiActivCertificates.Store({
                active: 1,
                orders: orders
            });

            await apiActiveSet.getResponse({});
            set_isActionLoading(false);
        };

        fetchActivate();
    };

    const generateCertificate = (data) => {

        const fetchGenerateCertificate = async () => {
            set_isActionLoading(true);
            const orders = data.map(item => item.id);

            let apiGenerateCode = ApiGenerateCertificate.Store({
                orders: orders
            });

            await apiGenerateCode.getResponse({});
            set_isActionLoading(false);
        };

        fetchGenerateCertificate();
    };

    const handleProductsChange = (event) => {
        set_productIds(event.target.value);
    };

    const openModal = () => {
        setIsModalOpen(true);
      };
    
      const closeModal = () => {
        setIsModalOpen(false);
      };

    useLayoutEffect(() => {
        contextPage.setTitle(`Сертификаты`);
        contextContent.setTitle(`Сертификаты`);
    }, [contextContent, contextPage]);

    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="phone"
            name="phone"
        >
            Телефон
        </ListHeaderItem>,
        <ListHeaderItem
            key="email"
            name="email"
        >
            Email
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
        >
            Код сертификата
        </ListHeaderItem>,
        <ListHeaderItem
            key="price"
            name="price"
        >
            Итого, руб
        </ListHeaderItem>,
        <ListHeaderItem
            key="product"
            name="product"
            isSort={false}
        >
            Продукт
        </ListHeaderItem>,
        <ListHeaderItem
            key="promocode"
            name="promocode"
        >
            Промокод
        </ListHeaderItem>,
        <ListHeaderItem
            key="status"
            name="status"
        >
            Статус
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_active"
            name="is_active"
            isSort={false}
        >
            Актив.
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_at"
        //     name="created_at"
        // >
        //     Создан
        // </ListHeaderItem>,
        // <ListHeaderItem
        //     key="updated_at"
        //     name="updated_at"
        // >
        //     Изменен
        // </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Пользователь</InputText>,
        <InputPhone className="input_wf" useReg={false} value={_phone} onChange={(e) => { set_phone(e.target.value) }}>Телефон</InputPhone>,
        <InputText className="input_wf" value={_email} onChange={(e) => { set_email(e.target.value) }}>Email</InputText>,
        <InputText className="input_wf" value={_code} onChange={(e) => { set_code(e.target.value) }}>Код</InputText>,
        <MultiSelect className="input_wf" options={products} value={_productIds} onChange={handleProductsChange}>Продукты</MultiSelect>,
        <InputText className="input_wf" value={_promocodeName} onChange={(e) => { set_promocodeName(e.target.value) }}>Промокод</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-certificates_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="phone" data-label={header[2].props.children}>{row.phone}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="email" data-label={header[3].props.children}>{row.email}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="code" data-label={header[4].props.children}><a target="_blank" href={row.file}>{row.code}</a></ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="price" data-label={header[5].props.children}>{row.total} ₽</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="product" data-label={header[6].props.children}>
                {row.products.map((product) => (
                    <div key={product.id}>
                        <div>Время: {product.minutes}</div>
                        <div>Цена: {product.price} ₽ ({product.total} ₽)</div>
                    </div>
                ))}
            </ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="promocode" data-label={header[7].props.children}>{row.promocode_name}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="status" data-label={header[8].props.children}>{row.status}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="is_active" data-label={header[9].props.children}>
                {
                    row.is_active ? <div className="is-active_icon--success"><VerifiedIcon /></div>
                        : <div className="is-active_icon--danger"><NewReleasesIcon /></div>
                }
            </ListBodyItem>,
            // <ListBodyItem className="admin-certificates_column-width" key="created_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-certificates_column-width" key="updated_at" data-label={header[11].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-certificates_column-width" key="show" >
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiOrderCertificates}
            renderRow={renderRow}
            useRowSelect={true}
            isActiomLoading={_isActionLoading}
            searchParams={{ id: _id, name: _name, phone: _phone, email: _email, code: _code, price: _price, products: _productIds, promocode: _promocodeName }}
            functions={
                {
                    'Отправить на Email': setMultiEmailData,
                    'Деактивировать': deactivateMultiCertificate,
                    'Активировать': activateMultiCertificate,
                    'Сгенерировать сертификаты': generateCertificate,
                }
            }
        />
        <MyModal className="certificate__email" isOpenModal={isModalOpen} closeModal={closeModal} title={"email"}>
            <div className="certificate__email_content">
                <InputText className="input_wf" value={_sendTo} onChange={(e) => { set_sendTo(e.target.value) }}>E-mail</InputText>,
                <Button onClick={sendMultiEmail}>Отправить</Button>
            </div>
        </MyModal>
    </>
}
