//Реакт
import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
//Библиотеки  и Api
import ApiAuth from "../../../../app/api/public/system/ApiAuth";
//Модули Layout
import LayoutAuth from "../../../modules/layouts/auth";
//Компоненты
import InputText from "../../../components/inputs/text";
import InputWrapper from "../../../components/input/__wrapper";
import Button from "../../../components/button";
//Стили
import './styles.css';
import '../../styles.css';

export default function FlushPasswordWithPage() {
    return (
        <LayoutAuth className="layout-auth">
            <FlushPasswordPage />
        </LayoutAuth>
    );
};
function FlushPasswordPage() {
    const [email, setEmail] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = await ApiAuth.SendResetLinkEmail({ email });
        if(data) {
            navigate('/login');
        }
    };
    return (
        <div>
            <div>
                <h3>Получить ссылку</h3>
            </div>
            <div className="auth__card">
                <div className="auth__card-body">
                    <form onSubmit={handleSubmit}>
                        <div className="credentials">
                            <InputWrapper className="auth__input-grid">
                                <InputText className="auth__login-email" value={email} onChange={e => setEmail(e.target.value)}>Email</InputText>
                            </InputWrapper>
                        </div>
                        <div className="auth__botom">
                            <div className="auth__botom_btn-wrap">
                                <Button type="submit" className="btn_rigth">Отправить</Button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
};