import CONFIG from "../inc/Config";
export default class StorageLocal{
    static async set(name, data){
        if(CONFIG.isDebug) console.log("set", name, data);
        await localStorage.setItem(name, data);
        return true;
    }
    
    static async get(name){
        let data = await localStorage.getItem(name);
        if(CONFIG.isDebug) console.log("get", name, data);
        return data;
    }
    static async remove(name){
        if(CONFIG.isDebug) console.log("remove", name);
        await localStorage.removeItem(name);
        return true;
    }
}
