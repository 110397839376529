//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../../inc/DateLib";
import ApiCategoryCalendar from "../../../../../../app/api/authorized/admin/event/ApiCategoryCalendar";
//Модули Content
import Page from "../../../../../modules/page";
import { ContextPage } from "../../../../../modules/page/pageContext";
import { ContextContent } from "../../../../../modules/content/contentContext";
//Модули view
import List from "../../../../../modules/list";
import ListHeaderItem from "../../../../../modules/list/__header-item";
import ListBodyItem from "../../../../../modules/list/__body-item";
//Компоненты
import Select from "../../../../../components/inputs/select";
import InputDate from "../../../../../components/inputs/date/date";
import InputNumber from "../../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../../components/button-link/__add";
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
//Стили
import './styles.css'

export default function CategoryCalendarIndex({ ...other }) {
    return <Page {...other}>
        <CategoryCalendarIndexContent {...other} />
    </Page>
}

function CategoryCalendarIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_categoryId, set_categoryId] = useState('');
    const [_dateStart, set_dateStart] = useState('');
    const [_dateEnd, set_dateEnd] = useState('');

    const categories = useSelector(state => state.adminOption.productCategories);

    const handleCategoryChange = (event) => {
        set_categoryId(event.target.value);
    };

    useLayoutEffect(() => {
        contextPage.setTitle(`Календари категорий`);
        contextContent.setTitle(`Календари категорий`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="category_id"
            name="category_id"
        >
            Категория
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Начало
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Окончание
        </ListHeaderItem>,
        <ListHeaderItem
            key="is_off"
            name="is_off"
        >
            Статус
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_at"
        //     name="created_at"
        // >
        //     Создана
        // </ListHeaderItem>,
        // <ListHeaderItem
        //     key="updated_at"
        //     name="updated_at"
        // >
        //     Изменена
        // </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <Select className="input_wf" options={categories} value={_categoryId} onChange={handleCategoryChange}>Категория</Select>,
        <InputDate className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Начало</InputDate>,
        <InputDate className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Окончание</InputDate>
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-calendar-category_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="category_id" data-label={header[1].props.children}>{row.category_name}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="date_start" data-label={header[2].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="date_end" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="is_off" data-label={header[4].props.children}>
                {
                    row.is_off ? <div className="is-off_content"><div className="is-off_icon--success"><VerifiedIcon/></div><div className="is-off_text">Открыты</div></div>
                    : <div className="is-off_content"><div className="is-off_icon--danger"><NewReleasesIcon/></div><div className="is-off_text">Закрыты</div></div>
                }
            </ListBodyItem>,
            // <ListBodyItem className="admin-calendar-category_column-width" key="created_at" data-label={header[5].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-calendar-category_column-width" key="updated_at" data-label={header[6].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-calendar-category_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiCategoryCalendar}
            renderRow={renderRow}
            searchParams={{ id: _id, category: _categoryId, dateStart: _dateStart, dateEnd: _dateEnd }}
        />
    </>
}
