//Реакт
import React, { useState, useLayoutEffect, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
//Библиотеки и Api
import DateLib from "../../../../../inc/DateLib";
import ApiPromocode from "../../../../../app/api/authorized/admin/content/ApiPromocode";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import Select from "../../../../components/inputs/select";
import InputDate from "../../../../components/inputs/date"
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
import ButtonLinkShow from "../../../../components/button-link/__show";
import ButtonLinkAdd from "../../../../components/button-link/__add";
//Стили
import './styles.css';
//Material UI
import VerifiedIcon from '@mui/icons-material/Verified';
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import InputCheckbox from "../../../../components/inputs/checkbox/checkbox";

export default function PromocodeIndex({ ...other }) {
    return <Page {...other}>
        <PromocodeIndexContent {...other} />
    </Page>
}

function PromocodeIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_id, set_id] = useState('');
    const [_name, set_name] = useState('');
    const [_code, set_code] = useState('');
    const [_type, set_type] = useState(null);
    const [_onlyActive, set_onlyActive] = useState(1);
    const [_availableProduct, set_availableProduct] = useState(null);
    const [_discountPersent, set_discountPercent] = useState('');
    const [_dateStart, set_dateStart] = useState(null);
    const [_dateEnd, set_dateEnd] = useState(null);

    const types = useSelector(state => state.adminOption.orderTypes);
    const products = useSelector(state => state.adminOption.products);

    const handleTypeChange = (event) => {
        set_type(event.target.value);
    };

    const handleAvailableProductChange = (event) => {
        set_availableProduct(event.target.value);
    };

    const handleShowActiveChange = (event) => {
        set_onlyActive(Number(!_onlyActive))
    }

 

    useLayoutEffect(() => {
        contextPage.setTitle(`Промокоды`);
        contextContent.setTitle(`Промокоды`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="name"
            name="name"
        >
            Название
        </ListHeaderItem>,
        <ListHeaderItem
            key="code"
            name="code"
        >
            Код
        </ListHeaderItem>,
        <ListHeaderItem
            key="discount"
            name="discount"
        >
            Скидка %
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Дата начала
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Дата оканчания
        </ListHeaderItem>,
        <ListHeaderItem
            key="order_types"
            name="order_types"
            isSort={false}
        >
            Тип заказа
        </ListHeaderItem>,
        <ListHeaderItem
            key="products"
            name="products"
            isSort={false}
        >
            Доступные продукты
        </ListHeaderItem>,
        <ListHeaderItem
            key="extraProduct"
            name="extraProduct"
        >
            Доп. продукт
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_at"
        //     name="created_at"
        // >
        //     Создан
        // </ListHeaderItem>,
        // <ListHeaderItem
        //     key="updated_at"
        //     name="updated_at"
        // >
        //     Изменен
        // </ListHeaderItem>,
        <ListHeaderItem
            key="create"
            name="create"
            isSort={false}
        >
            {contextContent.checkPermission('create') ? <ButtonLinkAdd href={contextPage.routes.create.url()} /> : null}
        </ListHeaderItem>,
    ];

    const filters = [
        <InputNumber className="input_wf" value={_id} onChange={(e) => { set_id(e.target.value) }}>id</InputNumber>,
        <InputText className="input_wf" value={_name} onChange={(e) => { set_name(e.target.value) }}>Название</InputText>,
        <InputText className="input_wf" value={_code} onChange={(e) => { set_code(e.target.value) }}>Код</InputText>,
        <InputNumber className="input_wf" value={_discountPersent} onChange={(e) => { set_discountPercent(e.target.value) }}>Скидка %</InputNumber>,
        <InputDate className="input_wf" value={_dateStart} onChange={(e) => { set_dateStart(e.target.value) }}>Дата начала</InputDate>,
        <InputDate className="input_wf" value={_dateEnd} onChange={(e) => { set_dateEnd(e.target.value) }}>Дата окончания</InputDate>,
        <Select className="input_wf" options={types} value={_type} onChange={handleTypeChange}>Тип заказа</Select>,
        <Select className="input_wf" options={products} value={_availableProduct} onChange={handleAvailableProductChange}>Доступные продукты</Select>,
        <InputCheckbox className="input_wf-input" value={_onlyActive} readOnly={false}  onClick={handleShowActiveChange}>Все/Актуальные</InputCheckbox>
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-promocodes_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="name" data-label={header[1].props.children}>{row.name}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="code" data-label={header[2].props.children}>{row.code}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="discount" data-label={header[3].props.children}>{row.discount_percent}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="date_start" data-label={header[4].props.children}>{DateLib.formatterDate(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="date_end" data-label={header[5].props.children}>{DateLib.formatterDate(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="order_types" data-label={header[6].props.children}>
                {row.order_types && row.order_types.length > 0 ? row.order_types.map((type) => (
                    <div className="renderRow_list" key={type.id}>
                        <li>{type.name}</li>
                    </div>
                )) : <li>Все</li>}
            </ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="products" data-label={header[7].props.children}>
                {row.products_allowed && row.products_allowed.length > 0 ? row.products_allowed.map((product) => (
                    <div className="renderRow_list" key={product.id}>
                        <li>{product.name}</li>
                    </div>
                )) : <li>Все</li>}
            </ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="extraProduct" data-label={header[8].props.children}>
                <div className="render-row_extra-product-name__content">
                    <div className="render-row_extra-product-name__content_icon">
                        <div className="render-row_extra-product-name__content_icon--success">{row.extra_product_is_active ? <VerifiedIcon/> : null}</div>
                        <div className="render-row_extra-product-name__content_icon--danger">{!row.extra_product_is_active ? row.extra_product_id && <NewReleasesIcon/> : null}</div>
                    </div>
                    <div className="render-row_extra-product-name__content_text">{row.extra_product_name}</div>
                </div>
            </ListBodyItem>,
            // <ListBodyItem className="admin-promocodes_column-width" key="created_at" data-label={header[9].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-promocodes_column-width" key="updated_at" data-label={header[10].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
            <ListBodyItem className="admin-promocodes_column-width" key="show">
                {contextContent.checkPermission('show') ? <ButtonLinkShow href={contextPage.routes.show.url({ id: row.id })} /> : null}
            </ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiPromocode}
            renderRow={renderRow}
            searchParams={{ id: _id, name: _name, code: _code, discount: _discountPersent, dateStart: _dateStart, dateEnd:_dateEnd, orderType: _type, availableProduct: _availableProduct, onlyActive: _onlyActive }}
        />
    </>
}
