import ReactQuill, { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

class AlignAttributor extends Parchment.Attributor.Class {}

const AlignClass = new AlignAttributor('customAlign', 'ql-al', {
  scope: Parchment.Scope.BLOCK,
  whitelist: [false, 'r', 'c', 'j']
});

Quill.register({
  'formats/customAlign': AlignClass,
  'attributors/class/customAlign': AlignClass
});

export default AlignClass;