//Реакт
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
//Системные представления
import Abort404 from "./public/system/Abort404";
//Страницы
import LoginPage from "./public/auth/login";
import FlushPasswordPage from "./public/auth/flush-password";
import ResetPasswordPage from "./public/auth/reset-password";

export default function SystemPages() {
    
    return <BrowserRouter>
        <Routes>
            <Route path="/login" element={<LoginPage />} />
            <Route path="/flush-password" element={<FlushPasswordPage />} />
            <Route path="/reset-password" element={<ResetPasswordPage />} />
            <Route path="*" element={<LoginPage />} />
        </Routes>
    </BrowserRouter>
}