import ReactQuill, { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

class ColorAttributor extends Parchment.Attributor.Class {}

const ColorClass = new ColorAttributor('color', 'ql-cl', {
    scope: Parchment.Scope.INLINE,
    whitelist: [false, 'red', 'orange', 'yellow', 'white', 'green', 'blue', 'pink']
});

Quill.register({
    'formats/color': ColorClass,
    'attributors/class/color': ColorClass
  });

export default ColorClass;