import ReactQuill, { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

class IndentAttributor extends Parchment.Attributor.Class {
    add(node, value) {
        if (value === '+1' || value === '-1') {
          let indent = this.value(node) || 0;
          value = (value === '+1' ? (indent + 1) : (indent - 1));
        }
        if (value === 0) {
          this.remove(node);
          return true;
        } else {
          return super.add(node, value);
        }
      }
    
      canAdd(node, value) {//проверка вызова функции add 
        return super.canAdd(node, value) || super.canAdd(node, parseInt(value));
      }
    
      value(node) {//возврат ноды при клике
        return parseInt(super.value(node)) || undefined;
      }
}

const IndentClass = new IndentAttributor('indent', 'ql-tab', {
    scope: Parchment.Scope.BLOCK,
    whitelist: [1, 2, 3, 4, 5]
});

Quill.register({
    'formats/indent': IndentClass,
    'attributors/class/indent': IndentClass
});
  

export default IndentClass;