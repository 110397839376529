import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
//Хранилище административной панели
import {setPermissions, setRoles, setProducts, 
        setOrderTypes, setPlatforms,
        setProductCategories, setBannerTypes, setBannerStatuses,
        setCalendarStatuses, setPromocodes,
        setCompetitions, setCompetitionTeamDisciplines, 
        setPaymentStatusesCertificate, setCompetitionProducts}  from "./app/reducers/admin/option/actions";
//Хранилище личного кабинета
import { setOrderProducts, setOrderPromocodes, setBookingTypes, setUserCertificates } from "./app/reducers/account/option/actions"
import { setPaymentStatuses, setPaymentTypes } from "./app/reducers/common/option/actions";
import DateLib from "./inc/DateLib";
import ApiAppInit from "./app/api/authorized/common/ApiAppInit";
import ApiAuth from "./app/api/public/system/ApiAuth";
export default function AppInit() {
  const dispatch = useDispatch();
  const expAccessToken = useSelector((state) => state.app.expAccessToken);
  const expRefreshToken = useSelector((state) => state.app.expRefreshToken);
  //Административная панель
  const adminPermissionsCount = useSelector((state) => Object.keys(state.adminOption.permissions || {}).length);
  const adminRolesCount = useSelector((state) => Object.keys(state.adminOption.roles || {}).length);
  const adminProductsCount = useSelector((state) => Object.keys(state.adminOption.products || {}).length);
  const adminCompetitionProductsCount = useSelector((state) => Object.keys(state.adminOption.competitionProducts || {}).length);
  const adminProductCategoriesCount = useSelector((state) => Object.keys(state.adminOption.productCategories || {}).length);
  const adminPlatformsCount = useSelector((state) => Object.keys(state.adminOption.platforms || {}).length);
  const adminOderTypesCount = useSelector((state) => Object.keys(state.adminOption.orderTypes || {}).length);
  const adminBannerTypesCount = useSelector((state) => Object.keys(state.adminOption.bannerTypes || {}).length);
  const adminBannerStatusesCount = useSelector((state) => Object.keys(state.adminOption.bannerStatuses || {}).length);
  const adminCalendarStatusesCount = useSelector((state) => Object.keys(state.adminOption.calendarStatuses || {}).length);
  const adminPromocodeCount = useSelector((state) => Object.keys(state.adminOption.promocodes || {}).length);
  const adminCompetitionsCount = useSelector((state) => Object.keys(state.adminOption.competitions || {}).length);
  const adminCompetitionTeamDiscpilinesCount = useSelector((state) => Object.keys(state.adminOption.competitionTeamDiscpilines || {}).length);
  const adminPaymentStatusesCertificate = useSelector((state) => Object.keys(state.adminOption.paymentStatusesCertificate || {}).length);
  //Личный кабинет
  const accountOrderProductsCount = useSelector((state) => Object.keys(state.accountOption.orderProducts || {}).length);
  const accountOrderPromocodesCount = useSelector((state) => Object.keys(state.accountOption.orderPromocodes || {}).length);
  const accountBookingTypesCount = useSelector((state) => Object.keys(state.accountOption.bookingTypes || {}).length);
  const accountUserCertificatesCount = useSelector((state) => Object.keys(state.accountOption.setUserCertificates || {}).length);
  //Общин опции
  const commonPaymentStatusesCount = useSelector((state) => Object.keys(state.commonOption.paymentStatuses || {}).length);
  const commonPaymentTypesCount = useSelector((state) => Object.keys(state.commonOption.paymentTypes || {}).length);

  useLayoutEffect(()=>{
    const load = async () =>{
      let apiAppInit = ApiAppInit.Index({});
      let data = await apiAppInit.getResponse();

      if(!data) return;
      //Административная панель
      if('admin_permissions' in data) dispatch(setPermissions(data.admin_permissions));
      if('admin_roles' in data) dispatch(setRoles(data.admin_roles));
      if('admin_products' in data) dispatch(setProducts(data.admin_products));
      if('admin_competition_products' in data) dispatch(setCompetitionProducts(data.admin_competition_products));
      if('admin_product_categories') dispatch(setProductCategories(data.admin_product_categories));
      if('admin_platforms' in data) dispatch(setPlatforms(data.admin_platforms));
      if('admin_order_types' in data) dispatch(setOrderTypes(data.admin_order_types));
      if('admin_banner_types' in data) dispatch(setBannerTypes(data.admin_banner_types));
      if('admin_banner_statuses' in data) dispatch(setBannerStatuses(data.admin_banner_statuses));
      if('admin_calendar_statuses' in data) dispatch(setCalendarStatuses(data.admin_calendar_statuses));
      if('admin_promocodes' in data) dispatch(setPromocodes(data.admin_promocodes));
      if('admin_competitions' in data) dispatch(setCompetitions(data.admin_competitions));
      if('admin_competition_team_disciplines' in data) dispatch(setCompetitionTeamDisciplines(data.admin_competition_team_disciplines));
      if('admin_cetrificates' in data) dispatch(setPaymentStatusesCertificate(data.admin_cetrificates));
      //Личный кабинет
      if('account_order_products' in data) dispatch(setOrderProducts(data.account_order_products));
      if('account_order_promocodes' in data) dispatch(setOrderPromocodes(data.account_order_promocodes));
      if('account_booking_types' in data) dispatch(setBookingTypes(data.account_booking_types));
      if('account_certificates' in data) dispatch(setUserCertificates(data.account_certificates));
      //Общие опции
      if('common_payment_statuses' in data) dispatch(setPaymentStatuses(data.common_payment_statuses));
      if('common_payment_types' in data) dispatch(setPaymentTypes(data.common_payment_types));
    }
    if(
        //Административная панель
        adminPermissionsCount === 0
        || adminRolesCount === 0
        || adminProductsCount == 0
        || adminCompetitionProductsCount == 0
        || adminProductCategoriesCount == 0
        || adminPlatformsCount == 0
        || adminOderTypesCount == 0
        || adminBannerTypesCount == 0
        || adminBannerStatusesCount == 0
        || adminCalendarStatusesCount == 0
        || adminPromocodeCount == 0
        || adminCompetitionsCount == 0
        || adminCompetitionTeamDiscpilinesCount == 0
        || adminPaymentStatusesCertificate == 0
        //Личный кабинет
        || accountOrderProductsCount == 0
        || accountOrderPromocodesCount == 0
        || accountBookingTypesCount == 0
        || accountUserCertificatesCount == 0
        //Общие опции
        || commonPaymentStatusesCount == 0
        || commonPaymentTypesCount == 0
      ) load();
      // eslint-disable-next-line
  }, []);
  //refresh AccessToken
  useLayoutEffect(()=>{
    const refresh = () => {
      let milsec = parseInt(expRefreshToken - DateLib.now('unix'));
      if(milsec > 0) ApiAuth.RefreshToken();
      else ApiAuth.Logout();
    }
    let timer = null;
    let milsec = parseInt(expAccessToken - DateLib.now('unix') - 5000);
    if(milsec > 0){
      timer = setTimeout(() => {
        refresh();
      }, milsec);
    }else refresh();
    return () =>{
      if(timer) clearTimeout(timer);
    }
  }, [expAccessToken, expRefreshToken]);
  return null;
}