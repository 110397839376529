import ApiModelWithAuth from "../../../../models/ApiModelWithAuth";

export default class ApiCertificateCheck extends ApiModelWithAuth{
    //formatterItem = formatterOrder;
    paths = {
        store: "authorized/account/booking/certificate/check",
    }

    getParamsForStore({code}){
        let data = {};
        if(code) data['code'] = code;
        return data;
    }
}