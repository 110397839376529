//Реакт
import React, { useState } from "react";
//Библиотеки  и Api
import CONFIG from "../../../../inc/Config";
import ApiAuth from "../../../../app/api/public/system/ApiAuth";
//Модули Layout
import LayoutAuth from "../../../modules/layouts/auth";
//Компоненты
import InputText from "../../../components/inputs/text";
import InputPassword from "../../../components/inputs/password";
import InputWrapper from "../../../components/input/__wrapper";
import Button from "../../../components/button";
//Стили
import './styles.css';
import '../../styles.css';

export default function LoginPageWithPage(){
  return (
      <LayoutAuth className="layout-auth">
        <LoginPage />
      </LayoutAuth>
  );
};
function LoginPage(){
  const [email, setEmail] = useState(CONFIG.auth.login);
  const [password, setPassword] = useState(CONFIG.auth.password);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await ApiAuth.Login({ email, password });
    window.location.replace("/authorized/common/profile");
  };
  return (
    <div>
      <div>
      <h3>Авторизация</h3>
      </div>
      <div className="auth__card">
        <div className="auth__card-body">
          <form onSubmit={handleSubmit}>
            <div className="credentials">
              <InputWrapper className="auth__input-grid">
                <InputText className="auth__login-email" value={email} onChange={e => setEmail(e.target.value)}>Email</InputText>
              </InputWrapper>
            </div>
            <div className="auth__credentials">
              <InputWrapper className="auth__input-grid">
                <InputPassword className="auth__login-password" value={password} onChange={e => setPassword(e.target.value)}>Пароль</InputPassword>
              </InputWrapper>
            </div>

            <div className="auth__botom">
                <div className="auth__botom_link">
                    <a href="/flush-password">Забыли пароль?</a>
                </div>
                <div className="auth__botom_btn-wrap">
                    <Button type="submit" className="btn_rigth auth__btn_right">Войти</Button>
                </div>
            </div>
          </form>
        </div>
      </div>
    </div>

  );
};