import ReactQuill, { Quill } from 'react-quill';

const Parchment = Quill.import('parchment');

class SizeAttributor extends Parchment.Attributor.Class {}

const SizeClass = new SizeAttributor('size', 'ql-fs', {
    scope: Parchment.Scope.INLINE,
    whitelist: ['10', false, '14', '16', '18', '20', '24']
});

Quill.register({
    'formats/size': SizeClass,
    'attributors/class/size': SizeClass
});
  

export default SizeClass;