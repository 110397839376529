//Реакт
import React, { useState, useLayoutEffect, useContext } from "react";
//Библиотеки и Api
import ApiBooking from "../../../../../app/api/authorized/admin/booking/ApiBooking";
import { route } from "../../../../../inc/Routes";
import DateLib from "../../../../../inc/DateLib";
//Модули Content
import Page from "../../../../modules/page";
import { ContextPage } from "../../../../modules/page/pageContext";
import { ContextContent } from "../../../../modules/content/contentContext";
//Модули view
import List from "../../../../modules/list";
import ListHeaderItem from "../../../../modules/list/__header-item";
import ListBodyItem from "../../../../modules/list/__body-item";
//Компоненты
import InputText from "../../../../components/inputs/text";
import InputNumber from "../../../../components/inputs/number/number";
//Стили
import './styles.css';

export default function BookingIndex({ ...other }) {
    return <Page {...other}>
        <BookingIndexContent {...other} />
    </Page>
}

function BookingIndexContent({ ...other }) {
    const contextContent = useContext(ContextContent);
    const contextPage = useContext(ContextPage);

    const [_type, set_type] = useState('');
    const [_fio, set_fio] = useState('');

    useLayoutEffect(() => {
        contextPage.setTitle(`Бронирования`);
        contextContent.setTitle(`Бронирования`);
    }, [contextContent, contextPage]);
    const header = [
        <ListHeaderItem
            key="id"
            name="id"
        >
            №
        </ListHeaderItem>,
        <ListHeaderItem
            key="order_id"
            name="order_id"
        >
            Заказ
        </ListHeaderItem>,
        <ListHeaderItem
            key="user"
            name="user"
        >
            Пользователь
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_start"
            name="date_start"
        >
            Начало
        </ListHeaderItem>,
        <ListHeaderItem
            key="date_end"
            name="date_end"
        >
            Окончание
        </ListHeaderItem>,
        <ListHeaderItem
            key="minutes"
            name="minutes"
        >
            Минуты
        </ListHeaderItem>,
        <ListHeaderItem
            key="type_id"
            name="type_id"
        >
            Приобритен через
        </ListHeaderItem>,
        <ListHeaderItem
            key="status_id"
            name="status_id"
        >
            Статус бронирования
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment"
            name="payment"
            isSort={false}
        >
            Оплата
        </ListHeaderItem>,
        <ListHeaderItem
            key="check_status_id"
            name="check_status_id"
        >
            Проверка
        </ListHeaderItem>,
        <ListHeaderItem
            key="payment_type_id"
            name="payment_type_id"
        >
            Тип оплаты
        </ListHeaderItem>,
        <ListHeaderItem
            key="count_people"
            name="count_people"
        >
            Количество человек
        </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_at"
        //     name="created_at"
        // >
        //     Созданно
        // </ListHeaderItem>,
        // <ListHeaderItem
        //     key="created_at"
        //     name="created_at"
        // >
        //     Изменено
        // </ListHeaderItem>,
    ];

    const filters = [
        <InputText className="input_wf" value={_fio} onChange={(e) => { set_fio(e.target.value) }}>Пользователь</InputText>,
        <InputText className="input_wf" value={_type} onChange={(e) => { set_type(e.target.value) }}>Приобритен через</InputText>,
    ];

    const renderRow = (row, i) => {
        return [
            <ListBodyItem className="admin-bookings_column-width" key="id" data-label={header[0].props.children}>{row.id}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="order_id" data-label={header[1].props.children}>{ row.order_id }</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="user" data-label={header[2].props.children}><a href={route(`authorized.admin.user.users.show`,{id: row.user_id})}>{row.user_fio}</a></ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="date_start" data-label={header[3].props.children}>{DateLib.formatterDateTime(row.date_start)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="date_end" data-label={header[4].props.children}>{DateLib.formatterDateTime(row.date_end)}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="minutes" data-label={header[5].props.children}>{row.minutes}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="type_id" data-label={header[6].props.children}>{row.type_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="status_id" data-label={header[7].props.children}>{row.status_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="payment" data-label={header[8].props.children}>
                {row.order_total ? <div>{row.order_total} ₽</div> : null}
                {row.balance_user_name ? <div>{row.balance_user_name}</div> : null}
                {row.time_type_name ? <div>{row.time_type_name}</div> : null}
                {row.time_minutes ? <div>{row.time_minutes}</div> : null}
            </ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="check_status_id" data-label={header[9].props.children}>{row.check_status_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="payment_type_id" data-label={header[10].props.children}>{row.payment_type_name}</ListBodyItem>,
            <ListBodyItem className="admin-bookings_column-width" key="count_people" data-label={header[11].props.children}>{row.count_people}</ListBodyItem>,
            // <ListBodyItem className="admin-bookings_column-width" key="created_at" data-label={header[12].props.children}>{DateLib.formatterDateTime(row.created_at)}</ListBodyItem>,
            // <ListBodyItem className="admin-bookings_column-width" key="updated_at" data-label={header[13].props.children}>{DateLib.formatterDateTime(row.updated_at)}</ListBodyItem>,
        ];
    }
    return <>
        <List
            header={header}
            filters={filters}
            ApiModel={ApiBooking}
            renderRow={renderRow}
            searchParams={{ user_fio: _fio, type: _type }}
        />
    </>
}
